import React from "react"
import styles from "../styles/topicsInFocus.module.css"

export default ({ boxTitle, boxSubTitle, children, className }) => {
	const cardStyleSet = [styles.tifCard]
	cardStyleSet.push(className)
	const cardStyle = cardStyleSet.join(" ")
	return (
		<div className={cardStyle}>
			<h2>{boxTitle}</h2>
			<div className={styles.tifCardInner}>{children}</div>
		</div>
	)
}
