import React from "react"
import styles from "../styles/strongSlant.module.css"
import Link from "gatsby-link"
import { useSiteMetadata } from "../hooks/use-site-metadata"

export default ({ style, precursors = [], children }) => {
	const { title } = useSiteMetadata()
	const precursorLinks = []
	precursors.forEach(step => {
		const { link, text } = step
		precursorLinks.push(
			<Link to={link} key={`breadcrumb-${link}`} className={styles.minimalLink}>
				{text}
			</Link>
		)
	})
	return (
		<nav>
			<span className={styles.siteBreadCrumb} style={style}>
				<Link to={"/"} key="homepage" className={styles.minimalLink}>
					{title}
				</Link>
				{precursorLinks}
				<span className={styles.breadCrumbEnd}>{children}</span>
			</span>
		</nav>
	)
}
